import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-2eb2cf40"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-grid" }
const _hoisted_2 = {
  key: 0,
  class: "p-field p-col-6 p-fluid"
}
const _hoisted_3 = { for: "specialists" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "dropdown-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.showDialog,
    "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showDialog) = $event)),
    header: _ctx.$t('user.delete'),
    modal: true
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        label: _ctx.$t('common.cancel'),
        class: "p-button-secondary p-button-medium",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showDialog = false))
      }, null, 8, ["label"]),
      _createVNode(_component_Button, {
        label: _ctx.$t('common.deleteUser'),
        class: _normalizeClass(["p-button p-button-medium", { 'p-button-outlined p-disabled': _ctx.disableSubmit, 'delete-user-confirm-button ': !_ctx.disableSubmit }]),
        loading: _ctx.waitSubmit,
        disabled: _ctx.disableSubmit,
        onClick: _ctx.onSubmit
      }, null, 8, ["label", "class", "loading", "disabled", "onClick"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("form", null, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("p", null, _toDisplayString(_ctx.havePatients ? _ctx.$t('user.sureToDeleteWithPatients') : _ctx.$t('user.sureToDelete')), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('messages.actionNotUndone')), 1),
          (_ctx.havePatients)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t('user.specialistAssociated')), 1),
                _createVNode(_component_Dropdown, {
                  id: "specialists",
                  modelValue: _ctx.userReassign.userIdToReassign,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.userReassign.userIdToReassign) = $event)),
                  placeholder: _ctx.$t('common.chooseOption'),
                  options: _ctx.professionals,
                  "option-label": "label",
                  optionValue: "id"
                }, {
                  value: _withCtx((slotProps) => [
                    (slotProps.value)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.professionals?.find((specialist) => specialist.id == slotProps.value)?.name) + " " + _toDisplayString(_ctx.professionals?.find((specialist) => specialist.id == slotProps.value)?.surname), 1))
                      : _createCommentVNode("", true)
                  ]),
                  option: _withCtx((slotProps) => [
                    _createVNode(_component_Avatar, {
                      image: slotProps.option.picture,
                      class: "p-mr-2",
                      size: "small",
                      shape: "circle"
                    }, null, 8, ["image"]),
                    _createElementVNode("span", _hoisted_5, _toDisplayString(slotProps.option.name) + " " + _toDisplayString(slotProps.option.surname), 1)
                  ]),
                  _: 1
                }, 8, ["modelValue", "placeholder", "options"])
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }, 8, ["visible", "header"]))
}